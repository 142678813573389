import firebase from '../../../../firebase'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        firebase.ref(`users/${localStorage.getItem('userId')}`).once('value', snap => {
          resolve(snap.val())
        })
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        firebase.ref(`users/${localStorage.getItem('userId')}/profileUser`).once('value', snap => {
          resolve(snap.val())
        })
          .catch(error => reject(error))
      })
    },
    getChat(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line radix
        const idUserLogado = parseInt(localStorage.getItem('userId'))
        firebase.ref(`users/${idUserLogado}/contacts/${localStorage.getItem('idContact')}`).once('value', snap => {
          const contact = snap.val()
          localStorage.setItem('userActiveChat', contact.fullName)
          if (contact.chat) {
            localStorage.setItem('isNewChat', false)
            firebase.ref(`users/${localStorage.getItem('userId')}/chats/${userId}`).once('value', snap2 => {
              const chat = snap2.val()
              localStorage.setItem('qtMensagem', chat.chat.length)
              const data = {
                chat,
                contact,
              }
              resolve(data)
            })
          } else {
            localStorage.setItem('isNewChat', true)
            const data = {
              contact,
            }
            resolve(data)
          }
        })
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, { contactId, message, senderId }) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line radix
        const id = parseInt(localStorage.getItem('userId'))
        // eslint-disable-next-line radix
        const conId = parseInt(contactId)
        // eslint-disable-next-line radix
        const qtc = parseInt(localStorage.getItem('qtChats'))
        const newMessageData = {
          message,
          time: new Date(),
          senderId,
        }
        firebase.ref(`users/${contactId}/chats/${id}/chat/${localStorage.getItem('qtMensagem')}`).set({
          message: newMessageData.message,
          senderId: id,
          time: new Date(),
        })
        firebase.ref(`users/${contactId}/chats/${id}`).update({ unseenMsgs: 1 })
        firebase.ref(`users/${contactId}/chatsContacts/${localStorage.getItem('chatsContactsId')}/chat/`).set({
          id: qtc,
          lastMessage: {
            message,
            time: '2021-08-04T02:00:32.417Z',
            senderId: id,
          },
          unseenMsgs: 1,
        })
        firebase.ref(`users/${id}/chats/${contactId}/chat/${localStorage.getItem('qtMensagem')}`).set(newMessageData)
          .then(() => {
            // eslint-disable-next-line radix
            const countMsg = parseInt(localStorage.getItem('qtMensagem')) + 1
            localStorage.setItem('qtMensagem', countMsg)
          }).catch(error => reject(error))
        if (localStorage.getItem('isNewChat') === 'true') {
          localStorage.setItem('isNewChat', false)
          const chatCont = {
            about: 'Orgulho de ser VOZ',
            avatar: '/img/3.a31ed549.png',
            chat: {
              id: conId,
              lastMessage: {
                message,
                senderId: id,
                time: '2021-08-04T02:00:32.417Z',
              },
              unseenMsgs: 0,
            },
            fullName: localStorage.getItem('userActiveChat'),
            id: conId,
            role: 'Town planner',
            status: 'busy',
          }
          const chat = {
            id,
            userId: id,
            // eslint-disable-next-line radix
            chatsContactsId: parseInt(localStorage.getItem('qtChats')),
            unseenMsgs: 0,
            chat: [
              {
                message,
                time: '2021-08-20T02:44:24.128Z',
                senderId: id,
              },
            ],
          }
          const contChat = {
            id: qtc,
            lastMessage: {
              message,
              time: '2021-08-20T02:44:24.128Z',
              senderId: id,
            },
            unseenMsgs: 1,
          }
          firebase.ref(`users/${id}/chats/${contactId}`).set(chat)
          firebase.ref(`users/${id}/chatsContacts/${qtc}`).set(chatCont)
          firebase.ref(`users/${id}/contacts/${localStorage.getItem('idContact')}/chat`).set(contChat)
          // eslint-disable-next-line radix
          const newQtChat = parseInt(localStorage.getItem('qtChats')) + 1
          localStorage.setItem('qtChats', newQtChat)
          const data = {
            chat,
            newMessageData,
          }
          resolve(data)
        } else {
          const data = {
            newMessageData,
          }
          resolve(data)
        }
      })
    },
  },
}
